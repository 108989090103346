export const CATEGORIES:{title: string, links: Record<string, string>}[] = [
  {
    title: "About us",
    links: {
      "What is iCash?": "/what-is-icash",
      "Why iCash": "/why-icash",
      "How it works": "/how-it-works",
      "iCash reviews": "/reviews",
      "iCash loan app": "/best-mobile-loans-app",
      "iCash vs other lenders": "/icash-vs-other-lenders",
    },
  },
  {
    title: "Loans",
    links: {
      "Instant loans": "/instant-loans",
      "Personal loans": "/personal-loans",
      "Payday loans": "/payday-loans",
      "E-transfer loans": "/e-transfer-payday-loans",
      "Bad credit loans": "/bad-credit-loans",
      "Government benefit loans": "/payday-loans-with-government-benefits",
    },
  },
  {
    title: "Resources",
    links: {
      "Learn": "/learn",
      "Blog": "/blog",
      "Key terms": "/key-terms",
      "Loan calculator": "/calculator",
      "Budget calculator": "/budget-calculator",
    },
  },
  {
    title: "Provinces",
    links: {
      "Alberta": "/payday-loans-alberta",
      "British Columbia": "/payday-loans-british-columbia",
      "Manitoba": "/payday-loans-manitoba",
      "New Brunswick": "/payday-loans-new-brunswick",
      "Nova Scotia": "/payday-loans-nova-scotia",
      "Ontario": "/payday-loans-ontario",
      "Prince Edward Island": "/payday-loans-prince-edward-island",
    },
  },
  {
    title: "Legal",
    links: {
      "Responsible lending": "/responsible-lending",
      "Credit counsellors": "/credit-counsellors",
      "Privacy policy": "/privacy-policy",
      "Terms of use and conditions": "/terms-of-use-and-conditions",
    },
  },
  {
    title: "Help",
    links: {
      "FAQ": "/faq",
      "Contact us": "/contact-us",
      "Forgot password": "/forgot-password",
    },
  },
]