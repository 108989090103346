import React, { useCallback, useMemo, useState } from "react";
import { useOutsideClick } from "@helpers/useOutsideClick";
import { DesktopNavigation, DesktopNavItem, MobileNavigation, NavCategories, ProductCard } from "@brainfinance/icash-component-library";
import { MENUDATA } from "@containers/navigation/navigation-data";
import icashLogo from "@images/icash-logo-black.svg";
import { navigate } from "gatsby";

export const Navigation = () => {
  const [activeElement, setActiveElement] = useState<number | undefined>();

  const containerRef = useOutsideClick<HTMLDivElement>(null, () => {
    resetActiveMenu();
  });

  const resetActiveMenu = useCallback(() => {
    if (activeElement !== -1 && activeElement !== undefined) {
      setActiveElement(undefined);
    }
  }, [activeElement]);
  
  const resetActiveElement = useCallback(() => {
    setActiveElement(undefined);
  }, [activeElement]);

  const handleOnSearch = useCallback((value?: string) => {
    if (value && !!value.trim()) {
      navigate(`/blog-search?search=${value.trim()}`);
    }
  }, [navigate]);

  const isBrowser = useMemo(() => typeof window !== "undefined", []);
  
  let navigationProps: any = {
    logo: icashLogo,
    onLogin: () => navigate('/login'),
    onSignup: () => navigate('/signup'),
    onBlur: resetActiveMenu,
  };

  if (isBrowser && window.location.pathname.indexOf('/blog') === 0) {
    navigationProps = {
      ...navigationProps,
      onSearch: handleOnSearch,
      isSearchActive: activeElement === -1,
      onSearchClick: () => setActiveElement(-1),
      onSearchBlur: resetActiveElement,
    }
  }

  return (
    <>
      <DesktopNavigation {...navigationProps} ref={containerRef}>
        {MENUDATA.map((category: any, key: number) => (
          <NavCategories {...category} type="desktop" key={key} activeElement={activeElement} nb={key} onClick={() => setActiveElement(key)}>
            {category.products ? (
              <div className="navigation--products-grid">
                {category.products.map((product: any, key: number) => (
                  <ProductCard
                    key={key}
                    {...product}
                    animation
                    onClick={() => {
                      window.location.href = product.path;
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-wrap gap-[33px]">
                {category.items.map((item: any, key: number) => (
                  <DesktopNavItem key={key} {...item} />
                ))}
              </div>
            )}
          </NavCategories>
        ))}
      </DesktopNavigation>
      <MobileNavigation {...navigationProps}>
        {MENUDATA.map((category: any, key: number) => (
          <NavCategories type="mobile" key={key} title={category.title}>
            {category.products ? (
              <div className="navigation--products-grid">
                {category.products.map((product: any, key: number) => (
                  <ProductCard
                    key={key}
                    {...product}
                    animation
                    onClick={() => {
                      window.location.href = product.path;
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col">
                {category.items.map((item: any, key: number) => (
                  <span key={key} className="navigation--mobile-header-item">
                    <a href={item.path || "/"}>{item.label}</a>
                  </span>
                ))}
              </div>
            )}
          </NavCategories>
        ))}
      </MobileNavigation>
    </>
  )
};