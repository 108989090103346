import * as React from "react";
import { LifecycleAnimationType } from "@types";
import { DownloadAppBox, FixedFooter, FooterBox, FooterCategoryList, FooterMediaBox, LogoBox } from "@brainfinance/icash-component-library";
import { FooterProps } from "./footer";
import { Box } from "@components/box";
import { CATEGORIES } from "@containers/footer/footer-category-data";
// Images
import ios from "@images/iOS.svg";
import android from "@images/Android.svg";
import logo from "@images/icash-symbol-black-sized.svg";
import qrCode from "@images/website-footer-QR_QR-code.svg";

export const Footer = (props:FooterProps) => {
  const [animation, setAnimation] = React.useState<LifecycleAnimationType>("hide");
  const footerRef = React.useRef<Element>();
  const current = new Date();

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAnimation(entry.isIntersecting ? "hide" : "show");
      },
      { rootMargin: "0px" }
    );

    if (footerRef?.current) observer.observe(footerRef.current);

    return () => {
      if (footerRef?.current) observer.unobserve(footerRef.current);
    };
  }, []);

  return (
    <>
      <FixedFooter logo={logo} animate={animation}>Get 20% in cashback when paying on time. Learn more</FixedFooter>
      <Box ref={footerRef}>
        <FooterBox>
          <LogoBox logoImg={logo} />
          <h2 className="global--heading mt-[3.5rem] tracking-[-3px] md:mt-[2.5rem]">We are here to help</h2>
          <FooterCategoryList categoryData={props.categoryData} />
          <FooterMediaBox instagramLink="https://www.instagram.com/icash.ca/" facebookLink="https://www.facebook.com/icash.ca/" twitterLink="https://twitter.com/iCASH_ca">
            <div className="mr-[11rem] sm:mr-0 sm:mb-[4rem]">
              <DownloadAppBox customClass="!text-[13px] !font-500 !leading-[18px] !text-interface-500" downloadLink="https://icash-ca.app.link/7MQFYetR9X" iosLink="https://icash-ca.app.link/URLVQwAR9X" androidLink="https://icash-ca.app.link/7MQFYetR9X" iosImg={ios} androidImg={android} qrcodeImg={qrCode}>
                The iCash mobile app and borrow up to $1,500 anywhere, at any time.
              </DownloadAppBox>
            </div>
          </FooterMediaBox>
          <div className="public--footer-copyright">Copyright ©{current.getFullYear()} iCash.ca by Finabanx. All rights reserved</div>
        </FooterBox>
      </Box>
    </>
  );
};

Footer.defaultProps = {
  categoryData: CATEGORIES
}
