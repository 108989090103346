import iconInstant from "@images/products/ic-instant-loans-xl.svg";
import iconPersonal from "@images/products/ic-personal-loans-xl.svg";
// import iconCreditBuilder from "@images/products/ic-credit-builder-loans-xl.svg";
// import iconCreditScore from "@images/products/ic-credit-monitoring-xl.svg";
import smallImg from "@images/default.png";

export const MENUDATA = [
  {
    title: "Products",
    description:
      "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at. Maecenas auctor, lacus non auctor egestas lobortis.",
    items: [{ label: "Instant loans" }, { label: "Stretch bills" }, { label: "Credit builder" }],
    products: [
      {
        title: "Instant loans",
        children: "Up to $1500 approved in 5 minutes. Funds sent by e-transfer.",
        src: iconInstant,
        path: "/instant-loans/"
      },
      /* {
        title: "Credit score",
        children: "Description information, which will explain what the tool does.",
        src: iconCreditScore,
        path: "/credit-score/"
      },
      {
        title: "Credit builder loans",
        children: "Use reliable and honest credit building practices.",
        src: iconCreditBuilder,
        path: "/credit-builder-loans/"
      }, */
      {
        title: "Personal loans",
        children: "Pay your bills today and extend your repayments over 90 days.",
        src: iconPersonal,
        path: "/personal-loans/"
      }
    ]
  },
  {
    title: "Why iCash",
    description:
      "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at. Maecenas auctor, lacus non auctor egestas lobortis.",
    items: [
      {
        label: "Why choose our loans",
        path: "/why-icash",
        description:
          "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at."
      },
      {
        label: "How it works",
        path: "/how-it-works",
        description:
          "Mauris sit amet blandit eros, eu ultricies eros. Sed mollis felis dui, eget laoreet ante sagittis vel."
      },
      {
        label: "iCash app",
        path: "/best-mobile-loans-app",
        description: "Sed lobortis porta augue, ut consectetur felis tristique congue."
      }
    ]
  },
  {
    title: "Resources",
    description:
      "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at. Maecenas auctor, lacus non auctor egestas lobortis.",
    items: [
      {
        label: "Blog",
        src: smallImg,
        path: "/blog",
        description:
          "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec nec neque facilisis, gravida ipsum vitae, semper tellus."
      },
      {
        label: "Learn",
        src: smallImg,
        path: "/learn",
        description:
          "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at."
      }
    ]
  },
  {
    title: "Help",
    description:
      "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at. Maecenas auctor, lacus non auctor egestas lobortis.",
    items: [
      {
        label: "FAQ",
        src: smallImg,
        path: "/faq",
        description:
          "Curabitur scelerisque fringilla placerat. Etiam dignissim consectetur diam, id egestas risus hendrerit at."
      },
      {
        label: "Contact us",
        src: smallImg,
        path: "/contact-us",
        description:
          "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec nec neque facilisis, gravida ipsum vitae, semper tellus."
      }
    ]
  }
];