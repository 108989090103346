import { useEffect, useRef } from "react";

// Check when click outside
export function useOutsideClick<T extends HTMLElement>(initialValue: T | null, callback: () => void) {
  const ref = useRef<T>(initialValue);

  function handleClick(e: MouseEvent) {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return ref;
}
