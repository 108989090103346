import tw from "tailwind-styled-components";

export const LayoutBox = tw.div`
  my-0
  mx-auto

  pt-0
  pb-0

  last:pb-0
`;
