/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";

import { Footer } from "@containers/footer";
import { LayoutBox, Main } from "../style";
import { Navigation } from "@containers/navigation";

export const PageLayout: React.FC<any> = (props) => {
  return (
    <>
      <Navigation />
      <LayoutBox>
        <Main>{props.children}</Main>
      </LayoutBox>
      <Footer />
    </>
  );
};